.card{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    margin-top: 0.8rem;
    box-shadow: 5px 5px 4px 5px #80777b;
    position: relative;
    background-color: white;
  }

  .form {
    padding: 10px, 15px;
  }

  .card-image{
    height: 13rem;
    width: 100%;
    object-fit: cover;
  }

  .item-price{
    padding: 10px 15px;
    font-family: 'Roboto';
  }

  .item-price h4{
    color: orange;
  }

  .item-price h4 select{
    color: orange;
  }

  input:focus {
    outline: none;
    border: specify yours;
 }

  .item-price span{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .item-price select{
    text-decoration: none;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  .option select{
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    color: #000;
  }

  .dine{
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #000;
  }

  .dine radio{
    color: #000;
  }
   
  .card-btn{
    padding: 1rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 0.7rem;
    margin: 1rem;
    border: 2px solid #f52f81;
    background: transparent;
    color: #f52f81;
    border-radius: 10px;
    transition: background 200ms ease-in, #f52f81 200ms ease-in;
  }

  .card:hover{
      transform: scale(1.02);
  }

  .card-btn:hover{
      background: #f52f81;
      color: white;

  }

  
 
 
 