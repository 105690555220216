.about{
    background-color: rgb(238, 233, 215);
    padding: 50px 6rem;
}
.about .about-content .about-header{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding-bottom: 35px;
}
.about .about-content .about-header h1{
    color: green;
}
.divider{
    flex-grow:1;
    height: 1px;
    background-color: green;
}
.about-content .about-desc{
    display: flex;
    justify-content: space-between;
}

.about-content .about-photo{
    padding-left: 20px;
}
.about .about-us p{
    line-height: 1.7;
    word-spacing: 5px;
}

@media screen and (max-width: 800px){
    .about{
        padding: 30px 0.6rem;
    }
    .about-content .about-desc{
        flex-direction: column;
        flex: 1;
    }
    .about-content .about-photo{
        padding-left: 0;
        width: 270px;
        padding-top: 15px;
    }
    .about-us p{
        padding-right: 20px;
    }
}