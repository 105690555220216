.available{
    background-image: url("../images/bk/bg1.jpg");
    padding: 15px 4rem;
    height: auto;
}

.available .available-header{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding-bottom: 35px;
}

.available .available-header h1{
    color: #F52F81;
}

.divider{
    flex-grow:1;
    height: 1px;
    background-color: #F52F81;
}
.available .available-food{
    display: grid;
    position: relative;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 18rem));
    gap:2rem;
    justify-content: center;
    margin-bottom: 15px;
}

@media screen and (max-width: 800px){
    .available .available-header{
        font-size: small;

    }
    .divider{
        display: none;
    }
}