.poduct-cart{
    padding: 20px;
    display: flex;
}

.poduct-cart .product-container{
    width: 80%;
    padding: 10px 20px;
}

.poduct-cart .product-container .cart-item form{
    display: flex;
    flex-direction: row;
}

.poduct-cart .filters {
    color: white;
    padding: 20px 0px;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    max-height: 300px;
    margin-bottom: 50px;
}

.poduct-cart .filters span{
    padding-bottom: 15px;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.cart-item img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 20px;
}

.item-details h4 {
    margin: 0 0 5px 0;
    font-size: 20px;
    color: #e86658;
}

.item-details p{
    color: #c0392b;
}

.item-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.item-actions button,
.item-actions input {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
}

.item-actions input {
    width: 50px;
    text-align: center;
}

.item-actions button:hover,
.item-actions .remove-btn:hover {
    background-color: #f4f4f4;
}

.item-actions .remove-btn {
    border: none;
    background-color: #e74c3c;
    color: #fff;
}

.item-actions .remove-btn:hover {
    background-color: #c0392b;
}

.poduct-cart .filters button{
    width: 80%;
    height: 50px;
    background-color: #f52f81;
    color: #ddd;
    border-block-style: none;
    cursor: pointer;
    font-weight: bold;
    font-size: medium;
}

.poduct-cart .filters button:hover{
    color: #fff;
    background-color: #a66d85;
}

@media screen and (max-width: 800px){
    .poduct-cart{
        flex-direction: column;
    }

    .poduct-cart .product-container{
        width: 100%;
    }

    .poduct-cart .filters{
        width: 100%;
    }

    .cart-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .cart-item img {
        margin-bottom: 10px;
    }

    .item-actions {
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
    }

    .item-actions button,
    .item-actions input {
        margin-left: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .item-actions .remove-btn {
        width: 100%;
        margin-bottom: 0;
    }

    .cart-summary p span {
        float: none;
        display: block;
        margin-top: 5px;
    }
}