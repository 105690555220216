.contact{
    padding: 30px 4rem;
    background-color: rgb(238, 233, 215);
}

.contact .contact-header{
    display: flex;
    flex-direction:row;
    align-items: center;
    padding-bottom: 35px;
}

.contact .contact-header h1{
    color: green;
}

.divider{
    flex-grow:1;
    height: 1px;
    background-color: green;
}

.contact-content{
    display: flex;
}

.contact-content .contact-logo{
    padding-left: 15px;
}

input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    color: green;
  }

  label{
      color: green;
  }
  
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  .inquiry-container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  }

  .success{
    color: green;
    padding-left: 20px;
    display: none;
}

  @media screen and (max-width: 800px){
      .contact-content{
          flex: 1;
          flex-direction: column;
      }
      .contact-content .contact-logo{
        padding-top: 15px;
    }
  }