*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", "sans-serif";
    width: auto;
    max-width: 100vw;
}

.container{
    background-color: white;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  @media screen and (max-width: 800px){
    .container{
        width: 100vw;
    }
  }