.footer{
    height: auto;
    background-color: rgba(18, 23, 19, 1);
    padding: 10px;
    color: rgb(239, 245, 238);
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
}

.footer .media{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer .media .social ul li{
    list-style-type: none;
}

.footer .copyright{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: x-small;
}

.footer .copyright h7{
    color: rgb(245, 241, 238);
}

.footer .copyright h7:hover{
    color: rgb(223, 219, 24);
}

.footer .twitter{
    display: flex;
}

.footer .twitter h3{
    color: rgb(239, 245, 238);
}

.footer .twitter h3:hover{
    color: rgb(223, 219, 24);
}

.footer .facebook{
    display: flex;
    cursor: pointer;
}

.footer .facebook h3{
    color: rgb(239, 245, 238);
}

.footer .facebook h3:hover{
    color: rgb(223, 219, 24);
}

@media screen and (max-width: 800px){
    .footer{
        height: 300px;
        display: flex;
        line-height: 30px;
        flex: 1;
        bottom: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        overflow-x: hidden;
    }
    .footer .media{
        flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
    }

    .footer .media .address{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}