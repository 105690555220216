nav {
    margin: 0;
    background: rgb(246, 248, 246);
}

.contact-menu {
    margin: 0;
    padding: 10px;
    background-color: rgb(10, 11, 10);
    display: flex;
    justify-content: space-between;
}

.contact-menu .sms-menu {
    padding-left: 3rem;
    max-height: 30px;
    color: red;
}

.contact-menu .email-menu {
    padding-right: 4rem;
    color: #ba507c;
}

.menu-list {
    display: flex;
    padding-top: 2rem;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    max-height: 100px;
}
.menu-list .logo-bar{
    padding-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.menu-list .menu-icon {
    display: none;
    cursor: pointer;
    color: black;
    padding-right: 15px;
}

.menu-list .cart{
    display: none;
    cursor: pointer;
    color: #F52F81;
    padding-right: 15px;
    text-decoration: none;
}

.menu-list .cart a span{
    color: rgb(21, 1, 1);
}

.menu-list .menu {
    margin-right: 25px;
    background-color: rgb(10, 11, 10);
    max-width: 60%;
    border-radius: 90px;
    cursor: pointer;
}

.menu-list .menu ul{
    padding-right: 4rem;
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-type: none;
    display: flex;
    color: white;
    justify-content: space-around;
    position: relative;
}

.menu-list .menu ul li a{
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    text-decoration: none;
    color: white;
    border-radius: 90px;
    cursor: pointer;
}

.menu-list .menu ul li ul{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    top: 2.7rem;
    display: none;
    background-color: rgb(157, 199, 149);
    color: tomato;
    left: 5rem;
    padding-bottom: 15px;
    z-index: 1;
}

.menu-list .menu ul li a:hover{
    background-color: #9f496c;
    color: #F52F81;
}
.menu-list .menu ul li a:active{
    background-color: chartreuse;
}

.menu-list .menu ul li:hover > ul,
ul li ul:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}

.menu-list .menu ul li ul li{
    clear: both;
    width: 100%;
}

@media screen and (max-width: 800px){
    
    .menu-list{
        align-items: center;
        
    }
    .contact-menu .sms-menu {
        padding-left: 0.4rem;
        color: red;
    }

    .contact-menu .email-menu {
        padding-right: 0.8rem;
        color: #F52F81;
        font-size: 13px;
    }
    .menu-list .logo-bar{
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
    }


    .menu-list .menu {
        margin-right: 1px;
        max-width: 100%;
    }

    .menu-list .menu-icon {
        display: block;
        right: 0;
    }

    .menu-list .cart {
        display: block;
    }

    .menu-list .menu ul {
        flex-direction: column;
        width: 100%;
        position: absolute;
        background-color: rgba(12, 10, 11, 0.7);
        padding: 10px;
        right: 0;
        top: 110px;
        transition: all 0.5s ease-in-out;
        z-index: 99;
    }

    .menu-list .menu ul li a{
        padding: 7px;
    }

    .menu-list .menu li {
        margin-bottom: 0px;
        width: 100%;
        text-align: center;

    }

    .menu-list .menu li a {
        width: 100px;
        display: block;
        margin: 10px auto;
        padding: 10px 0px;
    }

    .menu-list .menu ul li ul{
        position: relative;
        background-color: #F52F81;
        padding-bottom: 1px;
        top: -1rem;
        left: 0.2rem;
    }

    .menu.close ul{
        left: -100%;
        transition: all 0.6s ease-in-out;
    }


}
